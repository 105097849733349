import {Injectable, NgModule} from '@angular/core';
import {CanLoad, Route, RouterModule, Routes, UrlSegment} from '@angular/router';
import {ConnexionComponent} from './connexion/connexion.component'
import {EnregistrementComponent} from "./connexion/enregistrement/enregistrement.component";
import {ListGameComponent} from "./list-game/list-game.component";
import {GamepageComponent} from "./gamepage/gamepage.component";
import {AccueilComponent} from "./accueil/accueil.component";
import {ProfilComponent} from "./profil/profil.component";
import {DevenirPartenaireComponent} from "./partenaire/devenir-partenaire/devenir-partenaire.component";
import {CreationComponent} from "./creation/creation.component";
import {DashboardComponent} from "./partenaire/dashboard/dashboard.component";

import {APP_BASE_HREF} from "@angular/common";
import {ResetPasswordComponent} from "./connexion/reset-password/reset-password.component";
import {environment} from "../environments/environment";
import {Observable} from "rxjs";
import {DevGuard} from "./dev.guard";
import {ValidateAccountComponent} from "./connexion/validate-account/validate-account.component";
import {PresentationGuard} from "./presentation.guard";
import {GameManagerComponent} from "./partenaire/game-manager/game-manager.component";
import {MainToolComponent} from "./devtools/main-tool/main-tool.component";
import {OrganizationInvitationComponent} from "./partenaire/organization-invitation/organization-invitation.component";
import {MembresComponent} from "./partenaire/dashboard/membres/membres.component";
import {GamesComponent} from "./partenaire/dashboard/games/games.component";
import {OrgGeneralComponent} from "./partenaire/dashboard/organization/org-general/org-general.component";
import {OrganizationComponent} from "./partenaire/dashboard/organization/organization.component";
import {RightsManagementComponent} from "./partenaire/game-manager/rights-management/rights-management.component";
import {FinancesComponent} from "./partenaire/dashboard/organization/finances/finances.component";
import {GmGamePagesComponent} from "./partenaire/game-manager/gm-game-pages/gm-game-pages.component";
import {PartenaireGuard} from "./guards/partenaire.guard";
import {BuildComponent} from "./partenaire/game-manager/build/build.component";
import {GameGuard} from "./guards/game.guard";
import {
  AchievementsManagementComponent
} from "./partenaire/game-manager/achievements-management/achievements-management.component";
import {GmGameComponent} from "./partenaire/game-manager/gm-game/gm-game.component";
import {AboutComponent} from "./about/about.component";


@Injectable()
class DevEnvGuard implements CanLoad {
  constructor() {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return !environment.production; // if prod = false it will load module
  }
}

const routes: Routes = [
  {path: 'login', component: ConnexionComponent},
  {path: 'register', component: EnregistrementComponent},
  {path: 'about', component: AboutComponent},
  {path: 'library', component: ListGameComponent},
  {path: 'list-game', component: ListGameComponent},
  {path: 'game/:id', component: GamepageComponent, canActivate: [DevGuard, PresentationGuard]},
  {path: 'accounts/:id/settings', component: ProfilComponent},
  {path: 'account/settings', component: ProfilComponent},
  {path: 'game/:id/manage', component: GameManagerComponent},
  {path: '', component: AccueilComponent},
  {path: 'become-partner', component: DevenirPartenaireComponent},
  {path: ':idGame/create/:idGamePage', component: CreationComponent},
  {
    path: 'dashboard/:idOrganization/games/:idGame/manage',
    component: GameManagerComponent,
    canActivate: [GameGuard],
    children: [
      {path: 'game', component: GmGameComponent},
      {path: 'team', component: RightsManagementComponent},
      {path: 'achievements', component: AchievementsManagementComponent},
      {path: 'pages', component: GmGamePagesComponent},
      {path: 'development', component: BuildComponent},
    ]
  },
  {
    path: 'dashboard/:idOrganization',
    component: DashboardComponent,
    canActivate: [PartenaireGuard],
    children: [
      {
        path: 'organization',
        component: OrganizationComponent,
        children: [
          {path: 'general', component: OrgGeneralComponent},
          {path: 'members', component: MembresComponent},
          {path: 'finances', component: FinancesComponent},
        ]
      },
      {path: 'games', component: GamesComponent},

    ]
  },
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'validateAccount', component: ValidateAccountComponent},
  {path: 'dev-tools', component: MainToolComponent, canActivate: [PresentationGuard]},
  {path: 'organization/invitation', component: OrganizationInvitationComponent},
  {path: '', redirectTo: '/', pathMatch: 'full'},
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // active la restauration de la position de défilement
    anchorScrolling: 'enabled',           // active le défilement vers l'ancre
  })],
  exports: [RouterModule],
  providers: [
    {provide: environment.mainDomain, useValue: '/'},
  ],
})


export class AppRoutingModule {
}
